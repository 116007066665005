.fc-unthemed {
  .fc-view-container *,
  .fc-view-container *:before,
  .fc-view-container *:after {
    box-sizing: border-box;
  }
  .fc-header-toolbar {
    .fc-center {
      display: flex;
      .fc-button {
        padding: 0.2em;
      }
    }
  }
  .fc-view-container {
    .fc-timeline th,
    .fc-timeline td,
    .fc-timeline-event .fc-time,
    .fc-timeline-event .fc-title {
      white-space: initial;
    }
    .fc-toolbar {
      .fc-widget-header {
        width: 50%;
      }
    }
    .fc-body {
      .fc-scroller {
        min-height: 30px;
      }
      .fc-widget-content {
        box-sizing: border-box;
        .fc-cell-content {
          padding-top: 4px;
          .fc-expander {
            display: none;
          }
        }
        .fc-divider {
          .fc-cell-content {
            cursor: pointer;
            width: 152px; // group name here
          }
        }
        .fc-rows table tbody tr {
          box-sizing: border-box;
          height: 30px;
          padding: 5px;
          .fc-cell-text {
            display: inline;
          }
          &[data-resource-id] {
            min-height: 110px;
            .fc-time {
              font-weight: 500;
            }
            span.fc-cell-text {
              display: block;
              width: 152px; // group no. and sizes here
            }
            .fc-widget-content > div {
              min-height: 110px;
              .fc-cell-content {
                height: 100%;
              }
            }
            .fc-resource-area .fc-icon,
            .fc-icon {
              display: none;
            }
          }
        }
      }
      .fc-timeline-event {
        border-radius: 5px;
        box-sizing: border-box;
        height: 110px;
        display: block;
        overflow: hidden;
        .fc-title {
          position: absolute !important;
          font-size: 1em;
          min-width: 100em !important;
        }
        .fc-time-wrap {
          display: flex;
          bottom: 0;
          position: absolute;
        }
      }
    }
  }
  .fc-button-primary {
    color: #2C3E50;
    background-color: white;
    border: none;
    font-weight: 500;
    &:hover {
      box-shadow: 0 1px 5px 0 rgba(0,0,0,0.2),
      0 2px 2px 0 rgba(0,0,0,0.14),
      0 3px 1px -2px rgba(0,0,0,0.12);
      background-color: #2C3E50;
      color: white;
    }
  }
}

.react-datepicker-popper {
  z-index: 2000 !important;
}

#front-page-calendar-button {
  float: left;
}

#visible-notes {
  margin: 10px 0 0 0;
  background-color: #F3F3F3;
  padding: 10px;
  white-space: pre-line;
}

.MuiTooltip-tooltip {
  font-size: 0.9em !important;
}

@media print {
  @page {
    size: landscape !important;
  }

  body {
    margin: 0 !important;
  }

  .fc-unthemed {
    margin-top: -5rem !important;
    .fc-view-container {
      .fc-head {
        .fc-resource-area {
          width: 8% !important;
        }
        .fc-scroller {
          margin-bottom: 0 !important;
          border-bottom: #000 0.2rem solid !important;
        }
        .fc-time-area .fc-scroller .fc-scroller-canvas {
          width: 1200px;
        }
      }
      .fc-body .fc-timeline-event {
        border-top-width: 10px !important;
        height: 90px !important;
        margin-top: 1px !important;
      }
    }
  }

  table {
    width: auto;
    zoom: 72.5%;
  }

  h2 > span,
  #front-page-calendar-button,
  #front-page-print-button,
  .fc-unthemed .fc-now-indicator {
    display: none;
  }

  #notes-area {
    page-break-inside: avoid;
    border: 5px #F3F3F3 solid;

    #notes-button {
      font-size: large;
    }
  }
}
#simple-tabpanel-3 label {
  min-width: 150px;
  width: 150px;
}

.MuiFilledInput-adornedEnd {
  padding-right: 0;
}
.MuiInputAdornment-positionEnd {
  margin-left: 0;
}
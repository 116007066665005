// https://github.com/twbs/bootstrap/blob/v4-dev/scss/_variables.scss
// https://bootswatch.com/
// An example:
// $primary: #00ceff;

body {
  background-color: #e6e6e6 !important;
  > * {
    font-size: 15px !important;
    table > *,
    table > * > * ,
    table > * > * > *,
    table > * > * > * > * {
      font-size: 16px !important;
    }
  }
  a:hover {
    text-decoration: none;
  }

  h1,
  h2,
  h3,
  h4,
  h6 > span{
    font-family: "Raleway", sans-serif;
    font-weight: 600;
    letter-spacing: 0.5px
  }

  h1 {
    font-size: 2rem;
    padding: 5px;
  }
  h2 {
    margin: 10px 0;
    font-size: 1.7em;
    font-weight: 100;


  }
  h3 {
    font-size: 1.4em;

  }

}

.container-fluid {
  margin-top: 64px !important;
  @media screen and (min-width: 600px) {
    margin-left: 180px !important;
    width: auto !important;
  }
}

.offerEditor {
  min-height: 50vh !important;
  max-height: 100% !important;
}

.MuiTabs-indicator {
  height: 6px !important;
  border-top: solid 0.5px #c4b173;
}

.pointer {
  cursor: pointer;
}

.fc-event, fc-event:hover {
  color: black;
}
.MuiDrawer-paper {
  ul li {
    padding: 0;
    border-bottom: solid 1px #f4f4f4;
    a {
      width: 100%;
      padding: 20px;
    }
    span {
      font-size: 1.05rem;
      margin-left: 10px
    }
    &:hover {
      background: #f7f7f7;
    }
  }
}
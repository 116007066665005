.MuiDrawer-root a {
  color: #3d3d3f;
  font-weight: 500;
  font-size: 16px;
}

.MuiFilledInput-root {
  background-color: rgba(113, 113, 113, 0.09) !important;
}

.MuiFilledInput-input {
  padding: 7px 3px 7px 5px !important;
}

.MuiFilledInput-multiline {
  padding: 10px !important;
  .MuiFilledInput-input {
    padding: 0 !important;
  }
}

.MuiInputBase-inputSelect {
  padding: 7px 30px 7px 5px !important;
  background-color: rgba(113, 113, 113, 0.09) !important;
}

.MuiSelect-filled {
  padding-right: 30px !important;
}

.MuiInputAdornment-positionEnd .MuiIconButton-root {
  padding: 0 !important;
}

.MuiFilledInput-adornedEnd {
  padding: 0 !important;
  .MuiFilledInput-inputAdornedEnd {
    padding: 7px !important;
  }
  .MuiInputAdornment-positionEnd {
    margin: 0 2px 0 0 !important;
    .MuiSvgIcon-root {
      margin: 0 !important;
    }
  }
}
.MuiTypography-root [class*="MuiBox"] {
  padding: 10px 5px;
}
// SHARED

// color and position for writer's name
.newsletter-writer {
  color: gray;
  margin: 0.5em 0;
}

// float: right for buttons
.news-button {
  float: right;
}


// FRONT PAGE

// makes the read more and horizontal line go all the way, even if the newsletter would be very short
.newsletter-front-div {
  width: 100%;
}

// keeps the front page height correct, in case react-Dotdotdot acts up for unknown reason
.front-body-height-backup {
  max-height: 4.2em;
  overflow: hidden;
}

// style for the "Add news" -button
#add-news-button {
  margin-top: -2.5em;
  margin-right: 1em;
  background-color: #068a3f;
  color: #fff;

  &:hover {
    background-color: #067a3d;
  }
}

// style for the "Read more" -links
.read-more-link {
  text-decoration: underline;
  margin-top: -2.5em;
  margin-right: 0.5em;
  text-align: right;
  float: right;
}

// style for the date
.date-style {
  padding-top: 1em;
}


// NEWSLETTER PAGE

// style for the content of the page
.newsletter-page-div {
  padding: 1em 1em 0.2em 1em;
  margin-bottom: 1em;
}


// FORM / ADD / EDIT PAGE

// style for the rich text editor (RTE)
.rte-editor {
  max-height: 450px;
  overflow: auto;
  width: 100%;
}

// aligning the delete button
.delete-button {
  @media screen and (min-width: 960px) {
    margin-right: 1em;
    float: right;
  }
}

// aligning the back button in add page
.back-button-add {
  @media screen and (min-width: 960px) {
    margin-top: -3.2em;
  }
  margin-top: -3em;
}

// aligning the back button in edit page
.back-button-edit {
  @media screen and (min-width: 960px) {
    margin-top: -3.2em;
  }
  margin-top: -6.8em;
}

// notifies, if the rte is empty
.rte-filler-notifier {
  margin-left: 10em;
  margin-top: -2em;
  float: top;
  width: 1em;
  height: 1em;
  position: absolute;
  z-index: -2;

  @media screen and (max-width: 960px) {
    margin-left: 1em;
  }
}
